import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataSharingService {
  sharedData: any;
  selectedUser: any;
  projectId: any;
  criteriaId: any;
  projectSiteArea: any;
  projectBuiltUpArea: any;
  projectName: any;
  compliance_code: any;
  compliance_fields: any;
  feedbackIteration: any;
  compliance_sr_no: any;
  clientName: any;
  clientEmail: any;
  clientRole: any;
  clientId: any;
  buildings: any;
  holidayList: [];
  criteriaConfigFiles:any;
  impactData:any;
  // projectDetails:{
  //   id:any;
  //   name:any;
  //   workshop:any;
  //   feedbackIteration:any;
  //   siteArea:any;
  //   type:any;
  //   builtUpArea:any;
  //   occupancy:any;
  //   clientId:any;
  //   roleId:any;
  //   typicalBuildings:any;
  //   climateZone:any;
  //   maxSubmissionCount:any;
  //   currentSubmissionCount:any;
  //   pendingAt:any;
  //   projectCode:any;
  //   description:any;
  //   status:any;
  //   coordinator:any;
  //   site_area:any;
  //   built_up_area:any;
  // }
  clientProfile: string;
  clientUserName: string;

  private projectDetailsSubject = new BehaviorSubject<any>(null);
  projectDetails$ = this.projectDetailsSubject.asObservable();

  updateProjectDetailsData(newData: any) {
    this.projectDetailsSubject.next(newData);
  }

  private notificationDataSubject = new BehaviorSubject<any>(null);
  notificationData$ = this.notificationDataSubject.asObservable();

  updateNotificationData(newData: any) {
    this.notificationDataSubject.next(newData);
  }

  private projectCriteriaSubject = new BehaviorSubject<any>(null);
  projectCriteria$ = this.projectCriteriaSubject.asObservable();

  updateProjectCriteriaData(newData: any) {
    this.projectCriteriaSubject.next(newData);
  }

  constructor() {}
}
