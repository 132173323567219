import { Component, Input, ViewChild } from '@angular/core';
import { ProjectService } from '../../project.service';
import { ActionColumnComponent } from 'src/app/shared/action-colum/action-column.component';
import { DatePipe } from '@angular/common';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';

@Component({
  selector: 'app-view-activity-logs',
  templateUrl: './view-activity-logs.component.html',
  styleUrls: ['./view-activity-logs.component.scss'],
})
export class ViewActivityLogsComponent {
  @Input() projectData: any;
  response: any;
  audit: any;
  userId:any
  constructor(private projectService: ProjectService, private dataSharing: DataSharingService,private datePipe: DatePipe) {}
  ngOnInit() {
    console.log('call view activity ',this.projectData);
    this.userId = this.dataSharing.clientUserName;
    console.log(this.userId)
    this.getActivityLogs();
  }

  getActivityLogs(){
    this.projectService.getAudit(this.projectData, this.userId).subscribe((res:any)=>{
      console.log(res)
      const result = res;
      this.response = result.data
    })
  }

  formatDate(dateString: string): string {
    const formattedDate = this.datePipe.transform(dateString, 'dd MMM, yyyy');
    return formattedDate ? formattedDate : 'Invalid Date';
  }

}