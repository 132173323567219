import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FileDownloadService } from '../services/file-download/file-download.service';

@Component({
  selector: 'app-onboarding-component',
  templateUrl: './onboarding-component.component.html',
  styleUrl: './onboarding-component.component.scss'
})
export class OnboardingComponentComponent {
  content:any= []
  src:any
  constructor(
    private router: Router,
    private service: FileDownloadService
  ){

    this.service.getOnboardingfILE().subscribe((res)=>{
      let result:any = res
      this.src = result.data[0]
      console.log(this.src)
    })
    this.service.getOnboarding().subscribe((res)=>{
      console.log(res)
      this.content = res
    })
  }
  currentpage = 0;
 

  nextPage() {
    if (this.currentpage < this.content.length - 1) {
      this.currentpage++;
    }
  }

  prevPage() {
    if (this.currentpage > 0) {
      this.currentpage--;
    }
  }
  onSkip(){
    this.router.navigate(['./client/projects'])
  }

  downloadManual() {
    const link = document.createElement('a');
    link.href = this.src; // replace with your actual file path
    link.download = 'manual.pdf';
    link.click();
  }
}