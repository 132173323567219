import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserData } from 'src/app/model/UserData';

@Injectable({
  providedIn: 'root',
})
export class ProjectdetailService {
  private addMember = '/schoolmember';
  private Project_URL = '/project';
  private CRITERIA_UI = '/criteria/#';
  private Upload_Excel = '/project/importmembers';
  private downloadExcelFile = '/project/exportMembersCsv/#';
  private appraisal_URL = '/appraisal/#';
  private API_URL = '/user';
  private TIMELINE = '/project/timeline/#';
  private templateDownload = '/schoolmember/memberSheetTemplate';
  private get_impact_url = '/impact/getCriteriaCases/#';
  private impact_url = '/impact/#';

  constructor(private http: HttpClient) {}

  private fileUploadedSource = new Subject<void>();

  fileUploaded$ = this.fileUploadedSource.asObservable();

  public timeLine(projectCode: string) {
    return this.http.get(this.TIMELINE.replace('#', projectCode));
  }

  triggerFileUploaded() {
    this.fileUploadedSource.next();
  }

  public downloadExcel(projectCode: any): Observable<Blob> {
    const url = `${this.downloadExcelFile.replace('#', projectCode)}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  public getMemberDetail(projectCode: any) {
    const urlWithParams = `${this.addMember}?project_code=${projectCode}`;
    return this.http.get(urlWithParams);
  }

  public getSave(body: any, id: any) {
    return this.http.patch(this.Project_URL + '/' + id, body);
  }

  public addMembers(projectCode: string, body: any) {
    const url = `${this.addMember}/${projectCode}`;
    return this.http.post(url, body);
  }

  public editMembers(id: string, body: any) {
    return this.http.patch(`${this.addMember}/${id}`, body);
  }

  public deleteMembers(id: string) {
    return this.http.delete(`${this.addMember}/${id}`);
  }

  updateUser(body: any, userId: any): Observable<UserData> {
    const url = `${this.API_URL}/update/${userId}`;
    return this.http.patch<UserData>(url, body);
  }
  
  public getImpactData(id:string){
    return this.http.get(this.get_impact_url.replace('#',id))
  }

  public uploadImpactData(id:string,body:any){
    return this.http.post(this.impact_url.replace('#',id),body);
  }

  public updateImpactData(id:string,body:any){
    return this.http.patch(this.impact_url.replace('#',id),body);
  }

  //criteria api//

  public getCriteriaData(projectID: string) {
    return this.http.get(this.CRITERIA_UI.replace('#', projectID));
  }

  public updateAppraisalComplianceStatus(
    status: boolean,
    projectId: string,
    appraisal_code: string
  ) {
    const body = [
      { is_compliance_attached: status, appraisal_code: appraisal_code },
    ];
    return this.http.patch(this.appraisal_URL.replace('#', projectId), body);
  }

  //upload excel//

  public uploadExcelFile(
    projectCode: string,
    fileName: string,
    file: any,
    action: any
  ) {
    console.log(file);
    // let body = ''
    const formData = new FormData();
    formData.append('file', file, file.name);
    const url = `${this.Upload_Excel}/${projectCode}?filename=${fileName}&action=${action}`;
    return this.http.post(url, formData);
  }

  public downloadTemplate(): Observable<any> {
    const url = `${this.templateDownload}`;
    return this.http.get(url);  // Adjust the API call as needed
  }
  
}
